<template>
  <div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :scroll="{x:3600}"
      :show-pagination="false"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <span>
            <a @click="handleUpdate(record)" v-if="!record.is_pushed">推送</a>
            <a @click="handleUpdate(record)" v-if="record.is_pushed">重推</a>
            <a-divider type="vertical"/>
            <a @click="handleGoods(record)">商品</a>
            <a-divider type="vertical"/>
            <a @click="handleRecordList(record)">状态记录</a>
            <a-divider type="vertical"/>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelet(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </span>
        </template>
      </span>
    </s-table>
    <a-modal
      v-model="goodsVisible"
      title="总包详情"
      width="800px"
      :footer="null"
      v-if="goodsVisible"
    >
      <a-table
        size="middle"
        :columns="innerColumns"
        :data-source="innerData"
        :pagination="false"
      >
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import {
  e_ceb607_waybill_delete,
  e_ceb607_waybill_list,
  e_ceb607_waybill_partial_update
} from '@/api/ceb607'

export default {
  name: 'OrderReadList',
  components: {
    STable
  },
  props: {},
  data () {
    return {
      goodsVisible: false,
      expandedRowKeys: ['id'],
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '账单编号',
          width: 400,
          dataIndex: 'bill_no'
        },
        {
          title: '报文编号',
          width: 400,
          dataIndex: 'message_guid'
        },
        {
          title: '订单编号',
          width: 400,
          dataIndex: 'guid'
        },
        {
          title: '企业唯一编号',
          dataIndex: 'cop_no'
        },
        {
          title: '境内运输工具编号',
          width: 140,
          dataIndex: 'domestic_traf_no'
        },
        {
          title: '监管场所代码',
          width: 100,
          dataIndex: 'loct_no'
        },
        {
          title: '物流企业代码',
          width: 100,
          dataIndex: 'logistics_code'
        },
        {
          title: '物流企业名称',
          width: 100,
          dataIndex: 'logistics_name'
        },
        {
          title: '毛重',
          width: 100,
          dataIndex: 'gross_weight'
        },
        {
          title: '申报地海关代码',
          width: 150,
          dataIndex: 'customs_code'
        },
        {
          title: '运输方式',
          align: 'center',
          width: 100,
          dataIndex: 'traf_mode'
        },
        {
          title: '运输工具',
          align: 'center',
          dataIndex: 'traf_name'
        },
        {
          title: '航班航次号',
          align: 'center',
          dataIndex: 'voyage_no',
          customRender: (text) => !text ? '-' : text
        },
        {
          title: '申请类型',
          width: 100,
          align: 'center',
          dataIndex: 'app_type'
        },
        {
          title: '申请状态',
          align: 'center',
          dataIndex: 'app_status'
        },
        {
          title: '是否推送',
          width: 100,
          dataIndex: 'is_pushed',
          customRender: (text) => text === false ? '未推送' : '已推送'
        },
        {
          title: '报送时间',
          width: 200,
          dataIndex: 'app_time',
          customRender: (text) => text === '1970-01-01' ? '无' : text
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '300',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      innerColumns: [
        {
          title: '商品序号',
          key: 'gnum',
          dataIndex: 'gnum'
        },
        {
          title: '总包号',
          dataIndex: 'total_package_no'
        },
        {
          title: '清单编号',
          dataIndex: 'invt_no'
        },
        {
          title: '物流运单编号',
          dataIndex: 'logistics_no'
        },
        {
          title: '备注',
          dataIndex: 'note'
        }
      ],
      innerData: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', this.$route.query.batch)
        return e_ceb607_waybill_list(Object.assign({ batch: this.$route.query.batch }, parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            res.data.entries.forEach(item => {
              item.waybill_list.forEach(item => {
                item.id = item.id + 'son'
              })
            })
            return res.data
          })
      }
    }
  },
  created () {},
  methods: {
    handleRecordList (record) {
      console.log(record)
      // this.$emit('onStatus', record)
      this.$router.push({ path: '/e/customs_order/export/waybill/' + record.cop_no + '/records' })
    },
    handleGoods (record) {
      console.log(record.waybill_list, 12399)
      this.innerData = record.waybill_list
      this.goodsVisible = true
    },
    handleUpdate (record) {
      console.log(record)
      e_ceb607_waybill_partial_update(record.is_pushed ? { force: true } : {}, record.id).then((res) => {
        this.$refs.table.refresh()
      })
        .catch((err) => {
          console.log(err)
          this.$refs.table.refresh()
        })
    },
    handleDelet (record) {
      e_ceb607_waybill_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
        .catch((err) => {
          this.$message.error('网络出错,请联系管理员' + err)
        })
    }
  }
}
</script>
